<template>
    <v-container fluid :style='{"height": "88vh"}'>
        <v-layout fill-height>
            <iframe
                frameBorder="0"
                class="embed-responsive-item"
                style="width:100%"
                src="https://secah.hno.oaxaca.gob.mx/gpcfiles/"
            />
        </v-layout>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>